import { InferOutput, array, instance, object, optional, string } from 'valibot'
import { Database } from '~/types/supabase'

export interface ChapterEditFormProps{
  chapter: Database['public']['Tables']['chapters']['Row']
}

export const ChapterEditSchema = object({
  index: string(),
  title: string(),
  assetsFile: optional(instance(File)),
  pagesOriginal: array(instance(File)),
  pagesClean: array(instance(File))
})

export type ChapterEditFormType = InferOutput<typeof ChapterEditSchema>