import { FieldElement } from '@modular-forms/solid'
import { Component, Show, createSignal } from 'solid-js'
import { FileUploadFieldProps } from '~/components/file-upload-field/file-upload-field.interfaces'
import styles from '~/components/file-upload-field/file-upload-field.module.scss'
import UploadIcon from '~/assets/icons/cloud-upload.svg'
import { translations } from '~/translations'

const FileUploadField: Component<FileUploadFieldProps> = ({ name, label, description, error, value, onChange, ...props }) => {
  const classList = () => ({
    [`${styles.container}`]: true,
    [`${styles.hasError}`]: Boolean(error),
  })
  const [filename, setFilename] = createSignal<string>()

  const onFilesChange = (event: Event & { currentTarget: FieldElement; target: Element; }) => {
    const file = value
    if (file) {
      setFilename(file.name)
    }
    onChange(event)
  }

  return (
    <div classList={classList()}>
      <label
        for={name}
        class={styles.label}
      >
        {label}
      </label>
      <div class={styles.dropzone}>
        <Show when={filename()} fallback={(
          <div class={styles.emptyState}>
            <span>{translations().general.actions.import}</span>
            <UploadIcon class={styles.icon} />
          </div>
        )}>
          <div class={styles.currentFile}>
            <span>{filename()}</span>
            <UploadIcon class={styles.icon} />
          </div>
        </Show>
        <input
          class={styles.fileUploadInput}
          type='file'
          {...props}
          onChange={onFilesChange}
        />
      </div>
      {description && (
        <div class={styles.description}>
          {description}
        </div>
      )}
      {error && (
        <div class={styles.error}>
          {error}
        </div>
      )}
    </div>
  )
}

export default FileUploadField
