import { Component, createResource } from 'solid-js'
import styles from '~/components/forms/styles/complex-form.module.scss'
import { ChapterEditFormProps, ChapterEditFormType, ChapterEditSchema } from '~/components/forms/chapter-edit/chapter-edit.interfaces'
import { SubmitHandler, createForm, setResponse, valiForm } from '@modular-forms/solid'
import TextField from '~/components/fields/text/text'
import Button from '~/components/button/button'
import { updateChapter } from '~/services/database/chapters'
import { PostgrestError } from '@supabase/supabase-js'
import FileUploadField from '~/components/file-upload-field/file-upload-field'
import ImagesUploadField from '~/components/images-upload-field/images-upload-field'
import { getChapterData } from '~/services/current-document/chapter'
import FormError from '~/components/form-error/form-error'
import { translations } from '~/translations'

const ChapterEditForm: Component<ChapterEditFormProps> = ({ chapter }) => {
  const [form, { Form, Field }] = createForm<ChapterEditFormType>({
    initialValues: {
      index: chapter.index.toString(),
      title: chapter.title ?? ''
    },
    validate: valiForm(ChapterEditSchema),
  })

  const onSubmit: SubmitHandler<ChapterEditFormType> = async (values) => {
    console.log({
      values,
      chapter,
      chapterId: chapter.id
    })

    try{
      const data = await updateChapter({
        id: chapter.id,
        ...values
      })
      console.log(data)
      window.history.back()
    }catch(error){
      console.error(error)
      setResponse(form, {
        status: 'error',
        message: (error as PostgrestError).message
      })
    }
  }

  const [chapterData] = createResource(() => getChapterData(chapter.id))

  const existingSourcePages = () => {
    return chapterData.latest?.pages.original.map(page => page.url)
  }

  const existingTargetPages = () => {
    return chapterData.latest?.pages.clean.map(page => page.url)
  }

  return (
    <Form onSubmit={onSubmit} class={styles.form}>
      <div class={styles.head}>
        <div class={styles.primaryTitle}>
          Modifier le Chapitre
        </div>
      </div>
      <div class={styles.fieldColumnGroup}>
        <div class={styles.fieldColumn}>
          <div class={styles.fields}>
            <Field name="index">
              {(field, props) => (
                <TextField
                  label={translations().chapters.forms.create.chapterIndex}
                  {...field}
                  {...props}
                />
              )}
            </Field>
            <Field name="title">
              {(field, props) => (
                <TextField
                  label={`${translations().chapters.forms.create.chapterTitle} (${translations().general.fields.optional})`}
                  {...field}
                  {...props}
                />
              )}
            </Field>
            <Field name="assetsFile" type="File">
              {(field, props) => (
                <FileUploadField
                  label={translations().chapters.forms.update.assetsLabel}
                  description={translations().chapters.forms.update.assetsDescription}
                  {...field}
                  {...props}
                />
              )}
            </Field>
            <FormError response={form.response} />
            <Button
              type='submit'
              style='lagoon'
              isLoading={form.submitting}
            >
              {translations().general.actions.edit}
            </Button>
          </div>
        </div>
        <div class={styles.fieldColumn}>
          <div class={styles.fields}>
            <Field name="pagesOriginal" type="File[]">
              {(field, props) => (
                <ImagesUploadField
                  {...field}
                  {...props}
                  label={translations().chapters.forms.create.sourcePages}
                  existingURLs={existingSourcePages()}
                />
              )}
            </Field>
            <Field name="pagesClean" type="File[]">
              {(field, props) => (
                <ImagesUploadField
                  {...field}
                  {...props}
                  label={`${translations().chapters.forms.create.targetPages} (${translations().general.fields.optional})`}
                  existingURLs={existingTargetPages()}
                />
              )}
            </Field>
          </div>
        </div>
      </div>
    </Form>
  )
}

export default ChapterEditForm